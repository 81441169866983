@import '~antd/dist/antd.css';
.App{
  height: 100vh;
}
.add_product_box .ant-modal-body{
  height: 700px;
  overflow: auto;
}
.bf-container{
  border: 1px solid #ddd;
}
.add_product_box .bf-content{
  height: 350px;
}

.add_product_box #searchForm .ant-col-md-1{
  text-align: center;
}
.index_headline{
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 54px;
}
.order_modal .ant-modal-body{
  padding-top: 0 !important;
}
.fallModal_box{
  height: 400px;
  overflow-y: scroll;
}
.config_item_box{
  height: 300px;
  overflow-y: scroll;
}
.not_show{
  display: none;
}

.ant-modal-body{
  max-height: 700px;
  overflow-x: hidden;
}