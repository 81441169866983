*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0 !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.flex {
	display: flex;
}
.flex_just_betw{
	display: flex;
	justify-content: space-between;
}
.flex_just_around{
	display: flex;
	justify-content: space-around;
}
.flex_just_cen{
	display: flex;
	justify-content: center;
}
.flex_just_end{
	display: flex;
	justify-content: flex-end;
}
.flex_wrap{
	flex-wrap: wrap
}
.flex_cen{
	display: flex;
	align-items: center;
}
.w200{
  width: 200px;
}
.w300{
  width: 300px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px;
}
.mt40{
  margin-top: 40px;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}
.m10 {
  margin: 10px;
}
.m20 {
  margin: 20px;
}
.m30 {
  margin: 30px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.tx_c{
  text-align: center;
}
.tx_l{
  text-align: left;
}
.tx_r{
  text-align: right;
}
.bgf{
  background-color: #fff !important;
}
.bor {
  border: 1px solid #ddd;
}
.bor_t {
  border-top: 1px solid #ddd;
}
.bor_l {
  border-left: 1px solid #ddd;
}
.bor_r {
  border-right: 1px solid #ddd;
}
.bor_b {
  border-bottom: 1px solid #ddd;
}
.shadow {
  box-shadow: 0 4px 20px 0 rgba(0, 26, 79, 0.14);
}
.bold{
  font-weight: bold;
}
.c_FF801A{
  color: #FF801A;
}
.c_red{
  color: #FF423C;
}
.c_blue{
  color: #1890ff;
}
.underline{
  text-decoration: underline;
}
.cursor{
  cursor: pointer;
}
/*滚动条样式*/
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 2px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 0;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  background: #dedee3;
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #dedee3;
}
main{
  overflow: auto;
}
.configuration_submit{
  text-align: right;
}

#searchForm .ant-form-item-control{
  max-width: 100%;
}
.link_btn{
  font-size: 14px;
  color: #189fff;
  text-decoration: underline;
  cursor: pointer;
}
.cancel_text{
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  cursor: pointer;
}
.delete_text{
  font-size: 14px;
  color: #ff4d4f;
  text-decoration: underline;
  cursor: pointer;
}

.justify_sb{
  justify-content: space-between;
}
.c_8F9FB3{
  color: #8F9FB3;
}
.none{
  display: none;
}
.primary_btn{
  border: 1px solid #ccc;
  padding: 5px 12px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.primary_btn .name {
  margin-right: 5px;
}
.primary_btn .anticon{
  position: absolute;
  top: 4px;
  right: 2px;
  font-size: 12px;
  cursor: pointer;
}
.switch_btn{
  background-color: #ff8632 !important;
  color: #ffffff !important;
  border: none !important;
}
.switch_btns{
  background-color: #bfbfbf !important;
  color: #ffffff !important;
  border: none !important;
}